import React from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css'

import { makeStyles } from '@material-ui/core/styles';

import { Section, SectionAlternate } from 'components/organisms';
import Hero from 'views/DesignCompany/components/Hero/Hero'
import { Box, Divider } from '@material-ui/core';

import Brands from './components/Brands'
import Counterplus from './components/Counterplus'
import Videosec from './components/Videosec'
import Consumer from './components/Consumer'
import Testiment from './components/Testiment'
// import Events from 'views/Coworking/components/Events'
// import Videohero from 'components/Videohero'
// import { Agency } from 'views';
import Featuredrange from 'views/Featuredrange';
// import Hero from 'views/Logistics/components/Hero'
// import { CardBase } from 'components/organisms';
import Circletwo from 'components/Circletwo'

// import { CardBase } from 'components/organisms';
import { Typography } from '@material-ui/core';
import Herotwo from './components/Herotwo/Herotwo'
// import OpenIconSpeedDial from 'components/Sd'




import {

    AboutBottom,
    Features,
    // Hero,
    // Integrations,



} from './components';

import {

    features,

   

    brand,
    consume,
    testimonial,


} from './data';
import { SectionHeader } from 'components/molecules';
// import { articles } from 'views/CloudHosting/data';
import Herosec from 'components/Herosec';
import { Integrations } from 'views/DesignCompany/components/Integrations';




const useStyles = makeStyles(theme => ({
    fontWeight900: {
        fontWeight: 900,
      },
      fontWeightmax: {
        fontWeight: 1300,
      },
    sectionTrucking: {
        maxWidth: '100%',
        paddingRight: 0,
        paddingLeft: 0,
    },
    sectionAlternate: {
        background: 'transparent',
        backgroundImage: `linear-gradient(180deg, ${theme.palette.alternate.main} 100%, ${theme.palette.background.paper} 0%)`,
        [theme.breakpoints.up('md')]: {
          backgroundImage: `linear-gradient(180deg, ${theme.palette.alternate.main} 50%, ${theme.palette.background.paper} 0%)`,
        },
      },
    featuresSection: {
        background: 'url(https://ik.imagekit.io/j6phbjneuop/patterns-bg__1__tCGWqci_F9.svg) no-repeat center',
        backgroundSize: 'contain',
    },
    integrationsSection: {
        background: '#082032',
    },
    sectionNoPaddingTop: {
        paddingTop: 0,
    },
    reviewSection: {
        background: theme.palette.primary.dark,
    },
    aboutSection: {
        background: '#0c133e',
    },
}));

const Launch = () => {
    const classes = useStyles();
    // const [loading ,setLoading] = useState(false);

    // useEffect(()=>{
    //     setLoading(true)
    //     setTimeout(()=>{
    //         setLoading(false)

    //     },4000)
    
    
    
    
    // },[]);

    return (
      
     <>
        <div className='showcase'>

      
    
     
       
        
     
 
         
       
            <Herosec />
            
            

           
     

            <SectionAlternate>
   
   <SectionHeader
        
     title ={<span><Typography  variant="h4" className={classes.fontWeight900}>We are reimagining Healthcare </Typography><Typography Typography variant="h3" className={classes.fontWeight900}color="Primary"  component="span">  from DNA research to Protein research and its translational outcomes

     </Typography></span>}
     
   />
   <Circletwo/>

   </SectionAlternate>
            
           
   <div className={classes.featuresSection}>
        <Section>
          <Features data={features} />
        </Section>
      </div>
     
      <div class = "whatsappfloat">
      <WhatsAppWidget phoneNumber='911146170798' message = 'how can we help ?' textReplyTime = "typically replies within an hour" companyName = "premas support"/>
      </div>
      













            <SectionAlternate className={classes.integrationsSection}>
                
            <SectionHeader
                data-aos="zoom-out-down"
                data-aos-once = "false"
                  
        
        title ={<span><Typography color = "textSecondary"  variant="h4" className={classes.fontWeight900}>The Future Is Now !</Typography><br/> <Typography Typography variant="h3" className={classes.fontWeight900}color="Primary"  component="span"> Our bespoke solutions enable you to 
   
        </Typography>&rarr;</span>}

       

        
      />
              <Hero />
            </SectionAlternate>
            <Herotwo />

















          




     


            <Section>
            <SectionHeader
            className = {classes.fontWeightmax}
        subtitle="Our ensembled portfolio boasts of the most powerful, unique & high-throughput platforms in the market."
        // "Our products are unique and stand apart in the market."
        subtitleVariant="h5"
        // subtitle="Our portfolio boasts of diverse cutting-edge tools serving multiple research and diagnostic applications in the field of Genomics, Cell Biology, and Biopharma."
     
        
        // disableGutter
        // data-aos="fade-up
      />
      <br/>
    
       
            </Section>
            <Featuredrange />
  
          
     
      




   




            <Section >
       
            
            <SectionHeader
            data-aos="zoom-out-down"
            data-aos-once = "false"
            
             title={<span><Typography variant="h4" className={classes.fontWeight900}>Hola !    </Typography><Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900}>We are Premas Life Sciences</Typography></span>}
        
                />
                 <Box
                 paddingLeft = {6}
                 paddingRight = {6}
                 
                 >
          
          <Typography 
          align = 'center'
          data-aos="zoom-out-down"
          data-aos-once = "false"
          variant = 'body1'
          color = 'secondary'
          
          component="span" > Incepted in 2011 by a team of highly experienced and proficient professionals, we are a young, dynamic, focused life science company in Delhi.<br/> We aim to constantly be the technology & knowledge partners for the Indian research & clinical diagnostics community.</Typography>

  </Box>
  <br/>
  <br/>
  <Divider/>
  <br/>
  <br/>
                  
   

             
                <AboutBottom />
            </Section>

            <SectionAlternate
                 data-aos="zoom-out-down"
                 data-aos-once = "false">
       
                <Counterplus />
            </SectionAlternate>





            <Section
             data-aos="zoom-in-right"
             data-aos-once = "false">
           
                <Brands data={brand} />
            </Section>

            <SectionAlternate
             data-aos="zoom-out-down"
             data-aos-once = "false">
            
            

                <Videosec />
            </SectionAlternate>
            <Section>
                <Consumer data={consume} />
            </Section>



            <SectionAlternate>
                <SectionHeader
                label = 'testimonials'
                    title={
                        <span>
                           <Typography  variant="h4" component="span" className ={classes.fontWeight900}>Our </Typography>
                            <Typography color="secondary" variant="h3" component="span" className= {classes.fontWeight900}>Customers </Typography>
                            <Typography  variant="h4" component="span" className ={classes.fontWeight900}>Love </Typography>
                            <Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900} >us</Typography>
                        </span>
                    }
                    subtitle=" And boast highly of us. We are incredibly grateful for our customer's belief in our capabilities.
                    ."
                    align="center"
                    fadeUp
                />
                <Testiment data={testimonial} />
            </SectionAlternate>

   
     
        </div>

             
             {/* <OpenIconSpeedDial/> */}
             </>
    );
};

export default Launch;


