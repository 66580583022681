// import { Button } from '@material-ui/core';
import React from 'react'

// import { TypedText } from './molecules';
// import { Features } from 'views/Launch/components';









function Herosec() {
    return (
        <div className="showcase">
            
            <video  poster = "https://onwhicar.sirv.com/premas/main/Untitled%20(1).mp4?thumbnail=960" width= "100%" src="https://onwhicar.sirv.com/premas/main/Untitled%20(1).mp4" playing={true}
                autoPlay loop muted />
             

        </div>
    )
}

export default Herosec
