import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Link} from 'gatsby';
import { LearnMoreLink,} from 'components/atoms';

import {
  useMediaQuery,
  // colors,
  Grid,
  List,
  ListItem,
  // ListItemAvatar,
  // ListItemText,
  Typography,
  // Avatar,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader, } from 'components/molecules';


const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 400,
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
  avatar: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(-2),
    border: `4px solid ${theme.palette.background.paper}`,
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

const AboutBottom = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });



  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={6}
              data-aos="fade-up"
            >
              <Image
               data-aos="zoom-out-down"
               data-aos-once = "false"
                src="https://onwhicar.sirv.com/premas/main/cellbiopng.png"
                alt="Premas life Science"
                className={classes.coverImage}
                lazy={false}
              />
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-up">
            
              <List disablePadding>
              <SectionHeader

ctaGroup={[
  <Link to ='/Ourstory'>
  <LearnMoreLink
  color= 'secondary'
    title="Know Our Story"
  
    variant="h6"
  />
  </Link>
]}
                   />
           
                <ListItem disableGutters data-aos="fade-up">



                <SectionHeader align = 'center'

                // label = {
                //   <Typography 
                //   data-aos="zoom-out-down"
                //   data-aos-once = "false"
                  
                //   component="span" > Incepted in 2011 by a team of highly experienced and proficient professionals, we are a young, dynamic, focused life science company in Delhi. We aim to constantly be the technology & knowledge partners for the Indian research & clinical diagnostics community.</Typography>
                // }
             
                
                    subtitle={<span>We<Typography  variant="body1" component="span" className = {classes.fontWeight900}> endeavor to bring cutting-edge tools for applications across human health, agriculture, biopharma, environment, forensics, energy, and much more to researchers and innovation-focused organizations. With the advent of genomics & proteomics, leveraging the latest technology to gain an edge in mining and interpreting information has become imperative. With our ensemble range of products, we facilitate to answer the vital questions of people's research using some of the most innovative tools from across the world.
                    </Typography></span>}
       data-aos="zoom-out-down"
       data-aos-once = "false"
                />
              
              
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={isMd ? 4 : 2}
            direction={isMd ? 'row' : 'column-reverse'}
          >
            <Grid item xs={12} md={6} data-aos="fade-up">
            <br/>
                  <br/>
              <SectionHeader
               align = 'center'
             
                title={
                  <span>
                    <Typography color="secondary" variant="h6" component="span">It’s Science </Typography>{' '}
              & we choose wisely!
                  </span>
                }
          
              
                disableGutter
              />
              <List disablePadding>
               
              
                {/* <ListItem disableGutters data-aos="fade-up">
                  {people.map((item, index) => (
                    <Avatar
                      key={index}
                      className={classes.avatar}
                      {...item.authorPhoto}
                    />
                  ))}
                </ListItem> */}
                <ListItem disableGutters >
                <SectionHeader align = 'center'
                data-aos="zoom-out-left"
                data-aos-once = 'false'
                    subtitle={<span>Our<Typography className ={classes.fontWeight900}  variant="body1" component="span"> versatile portfolio of leading global technologies boasts of some of the most exciting genomics and cell biology tools manufactured by global giants like Illumina, Fluidigm, Agilent, 10X Genomics, and others have tremendously boosted the popularity of the company. Over the years, PLS's dedicated and persistent client-centric approach has transformed this enterprise from a young start-up to an established leader with a CAGR of 60% in a short period.</Typography></span>}

                />
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={6}
              data-aos="fade-up"
            >
              <Image
              data-aos="zoom-out-right"
              data-aos-once= 'false'
                src="https://onwhicar.sirv.com/premas/main/people/premaspeople.png"
                alt="..."
                className={classes.coverImage}
                lazy={false}
              />
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    </div>
  );
};

AboutBottom.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default AboutBottom;
