import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid,  } from '@material-ui/core';


import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  textWhite: {
    color: 'white',

  },
  avatar: {
    width: 300,
    height: 300,
    borderRadius: 0,
    background: 'transparent',
  },
  descriptionListIcon: {
    '& .description-list-icon__title, & .description-list-icon__subtitle': {
      color: 'white',
    },
  },
}));

const Integrations = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}     data-aos="fade-up"
    data-aos-duration="3000" data-aos-once = "false">
      <SectionHeader
  

title ={<span> <Typography className={classes.textWhite} variant="inherit" component="span">The Future is now ! </Typography><Typography Typography variant="h2" className={classes.fontWeight900}color="Primary"  component="span"> <br/>Our bespoke solutions enable you to 	&#8594;</Typography></span>}
//         title={
//           <span className={classes.textWhite}>
// The future is now!
// <br/> 
// {/* Heading */}
// 
// {/* subititle */}

//           </span>
//         }
        subtitle={
          <span className={classes.textWhite}>
            {/* Committed to ending diagnostic odysseys for rare disease */}
          </span>
        }
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="left"
            direction="column"
            xs={12}
            sm={4}
            md={4}
            data-aos={'fade-up'}
          >
       
            <DescriptionListIcon
         
              title ={item.name}
              subtitle={item.title}
              className={classes.descriptionListIcon}
            />
        
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Integrations.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Integrations;
