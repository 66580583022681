import React from 'react'

import { makeStyles } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import {Link} from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Circletwo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>


      <Grid container spacing={3}>

        <Grid item xs>
          <Container className="container">
          <Link to='/Genomic'>
            <video poster = "https://onwhicar.sirv.com/premas/main/genomics2.png" className='round' height="20%" width="100%" src="https://ik.imagekit.io/j6phbjneuop/Untitled_UEVuz5A4A.mp4" playing={true}
                autoPlay loop muted />
            </Link>





          </Container>

        </Grid>

       
       
        <Grid item xs>
        <Container className='thirdcircle'>
          <Link to='/Cellbiology'>
          <video poster = "https://onwhicar.sirv.com/premas/main/cellbio2.png" className='rounded' height="20%" width="100%" src="https://ik.imagekit.io/j6phbjneuop/New_Folder/Customer_Logos/cell24_I0t639YkG.mp4" playing={true}
              autoPlay loop muted />
              </Link>
          </Container>
        </Grid>
      </Grid>
      {/* <Grid container spacing={3}>
        <Grid item xs>
          <Paper className={classes.paper}>xs</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>xs=6</Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>xs</Paper>
        </Grid>
      </Grid> */}
    </div>
  );
}










