import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, } from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon, CardBase } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
 
 fontIconColor :{
   color:theme.palette.secondary.main,
   
    
    
    },
  

}));


const Features = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader 

data-aos = "fade-down-left"
data-aos-once="false"
            title={<span><Typography  variant="h4" className={classes.fontWeight900}>Bringing the most cutting-edge and niche Lifesciences tools for the</Typography> <Typography color="Primary" className = {classes.fontWeight900} variant="h3" component="span"> advancement of Indian Research & Clinical Diagnostics</Typography></span>}
           
     
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={4} key={index} data-aos = "zoom-in-up"  data-aos-once="false">
            <CardBase liftUp
            

 noShadow variant="outlined">
              <DescriptionListIcon
                icon={
                  <Icon className = {classes.fontIconColor}
                    size="large"
                    fontIconClass={item.icon}
                 
                  />
                }
                title={item.title}
                subtitle={item.subtitle}
                align="left"
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Features;
