



export const events = [
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/controller-front_M3cFg7H8l4.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/controller-front_M3cFg7H8l4.png',
        },
        title: 'Chromium-controller',
        description:
            'Compact , Sleek And Efficient the Chromium Controller uses advanced microfluidics to perform single cell partitioning and barcoding in a matter of minutes. Powered by Next GEM technology, the Chromium Controller enables integrated analysis of single cells at massive scale.',

    },
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/novaseq-6000-front-center-down_U22NV2YKh.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/novaseq-6000-front-center-down_U22NV2YKh.png',
        },
        title: 'Novaseq600',
        description:
            'Scalable throughout any flexibility for virtually any genome , sequencing method, and scale of the project. NovaSeq 6000 Sequencing System is by far our most powerful instrument, designed to adapt to your needs so groundbreaking discoveries are always within reach.',

    },
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/output-onlinepngtools__6__IM08Vef5f.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/output-onlinepngtools__6__IM08Vef5f.png',
        },
        title: 'Juno',
        description:
            'Increase productivity and efficiency with automated,cost-effective,and easy-to-use workflows for targeted DNAnext-generation sequencing(NGS) library preparation, gene expression analysis and genotyping by allele-specific PCR',

    },
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/Clarity-Digital-PCR-System_gL63ukAMH.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/Clarity-Digital-PCR-System_gL63ukAMH.png',
        },
        title: 'Digital-PCR',
        description:
            'The Clarity™ Digital PCR is a flexible and easy to use system for your digital PCR needs. Its proprietary high-density chip technology allows sample partitioning to be carried out rapidly and easily. It is the only chip-based digital PCR system that allows 96 reactions in a single run',

    },
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/sw_jess_front_8oqtDioWo.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/sw_jess_front_8oqtDioWo.png',
        },
        title: 'JESS',
        description:
            'Jess automates traditional Western blotting while maximizing multiplexing with multiple detection channels. Automation of protein separation and immunodetection eliminates many of the tedious, error-prone steps of traditional Western blotting which limit data quality.',

    },
    {
        cover: {
            src: 'https://ik.imagekit.io/j6phbjneuop/500506_1_s-ag9tz1s.png',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/500506_1_s-ag9tz1s.png',
        },
        title: 'Covaris',
        description:
            'The ME220 Focused-ultrasonicator is the multi-sample, multi-application benchtop sample preparation solution for every lab. Capable of 1 to 8 sample batch processing at the bench-top, this compact, easy-to-use system enables precise and accurate results with AFA-energetics®.',

    },

];





















export const testimonial = [
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/people/Taslimsarif%20sayed.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/Taslimsarif%20sayed.jpg',
        },
        authorName: 'Taslimarif Sayed ',
        authorOccupation: 'CEO & Director , C-camp',
        feedback:
            'Premas is certainly one of the best partners we have worked with, either for technology instrument provider or consumables provider. They have shown a very high level of integrity, needed scientific understanding and maturity in working with us. I truly appreciate Praveens role and contribution in building this healthy work environment. I look forward to continuing this further',
    },
    {
        authorPhoto: {
            src: 'https://ik.imagekit.io/j6phbjneuop/unnamed__11__CwqsiruDd.jpg',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/unnamed__11__CwqsiruDd.jpg',
        },
        authorName: 'Dr. Srinivasan Periathiruvadi',
        authorOccupation: ' Founder , Jevan Stem Cell Foundation',
        feedback:
            'We have been associated with them from April 2017 end when we started NGS HLA typing at Jeevan Stem Cell Foundation. The sales and service teams of Premas Life Sciences are excellent. Responses have always been prompt and the engineers are very helpful, dedicated and knowledgeable. When there has been a breakdown in the equipment they were immediately available online to check the system. Their timely suggestions in maintaining the instruments have helped us improve our quality. Their service helps us run our sequencing lab smoothly.',
    },
    {
        authorPhoto: {
            src: 'https://ik.imagekit.io/j6phbjneuop/unnamed__14__d6TB-ra6I.jpg',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/unnamed__14__d6TB-ra6I.jpg',
        },
        authorName: 'Dr Nilesh Nayee',
        authorOccupation: 'National Dairy Development Board',
        feedback:
            'Premas, as a truly professional and committed team; is always ready to find solutions suited to customer needs. Looking forward to working with you.',
    },
    {
        
    
        authorPhoto: {
            src: 'https://ik.imagekit.io/j6phbjneuop/unnamed__12__teEQAUTne.jpg',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/unnamed__12__teEQAUTne.jpg',
        },
        authorName: 'Mr .Lalith Kishore',
        authorOccupation: 'President & CEO - Reprocell',
        feedback:
            "There are very few suppliers who go beyond a transactional relationship and truly enter the zone of partnership. Premas is one such organization for us. The members of the sales team, application specialists, service and the leadership team have been extraordinary in their support and have made it a point to go above and beyond agreed timelines, schedules, deliverables and value every single time that we have interacted with them. At a personal level, I would not make any technology decisions without using Premas leadership as a sounding board. It is a delight to work with them and pleasure doing business.",
    },
    {
        authorPhoto: {
            src: 'https://ik.imagekit.io/j6phbjneuop/unnamed__13__kcqmrZyE-.jpg',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/unnamed__13__kcqmrZyE-.jpg',
        },
        authorName: 'Prof .Partha P .Majumdar ',
        authorOccupation: 'Founder - NIBMG Kalyani',
        feedback:
            " I thank you and your Premas Lifesciences team for continued, and often out-of-the-box, support to implement various large-scale genomics activities in the National Institute of Biomedical Genomics in a timely and cost-effective manner. As we expand our genomics activities, we shall look forward to your further help and support.",
    },
];





export const consume = [
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/aiims1.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/aiims1.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/igb-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/igb-modified.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/nipgr.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/nipgr.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/medegenome-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/medegenome-modified.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/icmr-modifie.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/icmr-modifie.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/lifecell-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/lifecell-modified.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/c-camp-modif.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/c-camp-modif.png',
        },
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/nibmg-m-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/nibmg-m-modified.png',
        },



    },

    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/igb-m-m-modified%20(1).png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/igb-m-m-modified%20(1).png',
        },



    },

    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/mibiome-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/mibiome-modified.png',
        },



    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/strand-m-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/strand-m-modified.png',
        },



    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/customers/icmr-modified.png',
            srcSet: 'https://onwhicar.sirv.com/premas/main/customers/icmr-modified.png',
        },



    },
   
  



];














export const brand = [
    {
        logo: 'https://onwhicar.sirv.com/premas/main/illuminalogo-new.png',
        name: 'illumina',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/10x/10x-Genomics-Logo-1.png',
        name: '10xgenomics',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/Twist_Bioscience_Official_Logo.png',
        name: 'Isohelix',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/CodexDNA-Logo%20(1).png',
        name: 'Codex',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/covaris/covaris.png',
        name: 'Covaris',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/Genscript/genscriptfinal.png',
        name: 'genscript',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/dna%20script/DNA-Script-Logo-med.png',
        name: 'DNA SCRIPT',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/Acea%20Biosciences/1200px-Agilent_Technologies-Logo.svg.png',
        name: 'Agilent',
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/askion/askions-v-small.png',
        name: 'Askion',
    },
];








export const trucking = [
    {
        icon: 'fas fa-layer-group',
        title: 'Built for developers',
        subtitle:
            'TheFront is built to make your life easier. Variables, build tooling, documentation, and reusable components.',
    },
    {
        icon: 'fab fa-sketch',
        title: 'Designed to be modern',
        subtitle:
            'Designed with the latest design trends in mind. TheFront feels modern, minimal, and beautiful.',
    },
    {
        icon: 'fas fa-code',
        title: 'Documentation for everything',
        subtitle:
            "We've written extensive documentation for components and tools, so you never have to reverse engineer anything.",
    },
];

export const features = [
    {
        icon: 'fas fa-dna',
        title: `We believe Genomics is the Future`,
        subtitle:
            'DNA holds massive power. Our globally leading partners are helping to unravel the mysteries of the genome.',
    },
    {
        icon: 'fas fa-digital-tachograph',
        title: 'We stay ahead with the changing time',
        subtitle:
            'We keep a sharp eye on the newly emerging research areas and advancements in technology.',
    },
    {
        icon: 'far fa-handshake',
        title: 'We provide excellent customer service',
        subtitle:
            'Our team’s customer support and handholding are the best across the industry. We believe in enabling our customers to excel in their research work.',
    },
];

export const team = [
    'Our sign up is dead simple. We only require your basic company information',
    'We support bulk uploading via SQL, integrations with most data storage products',
    "Simply select where you'd like to transfer your data ",
];

export const integrations = [
    {
        logo: "https://onwhicar.sirv.com/premas/main/dna.gif",
        name: 'READ DN',
        title:
            "Advanced genomics tools integrated with computational innovation to decode extensive genomic data are at the center of public health and healthcare. As clinical genomics hinges on the accurate reading of the genomic footprints of disease markers and therapeutic targets, advanced technologies, customizable tools, and gene panels have given birth to precision medicine. Interestingly, reading or sequencing the genome has become more accessible, faster, and affordable over the last two decades. Our globally renowned partners like Illumina and Universal Sequencing have the most qualitative platforms to fit your dream sequencing lab.",
    },
    {
        logo: 'https://onwhicar.sirv.com/premas/main/fluidigm.png',
        name: 'WRITE DNA',
        title:
            "Move over genetically modified species, whereby one or a few genes are genetically engineered. One can now write an entire new gene. As profound as it sounds, writing genomes or parts of it has been made possible through neat and innovative technologies in cloning methodologies and automation thereof. A do-it-yourself (DIY) gene writing/creating synthetic genes may seem fantasy-like; Hail—the world’s first automated gene printing platform BioXp 3250 from our partners Codex DNA . Imagine creating tens of long synthetic gene constructs in your own lab within hours, without having to do tedious plasmid preps or dealing with contamination or low yields. This can potentially accelerate advances in the fields of personalized medicine, antibody engineering, vaccine development, biologics, and more.",
    },
    {
        logo: 'https://loading.io/icon/t3p52z',
        name: 'EDIT DNA',
        title:
"Analogous to a film reel that can be edited with a cut here and a paste there, the genome is explored in newer ways through genome editing. Moreover, access to genome editing tools like CRISPR, TALENs, ZFNs, and MAGE are helping create new paradigms in diagnostics, therapeutic interventions, and GM foods. This has poised a revolutionary ecosystem for developing new molecular devices and game-changing immunotherapies like CARTs, genome-edited crops, and gene therapy-based treatments. In addition, drugs like Zolgensma for Spinal Muscular atrophy have paved new ways for researchers to solve long-impending healthcare problems. Our partners Twist Biosciences provide customized products assisting synthetic biology researchers in editing genomes the way they want.",
    },
];

export const reviews = [
    {
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
        },
        authorName: 'Veronica Adams',
        authorOccupation: 'Growth Marketer, Crealytics',
        feedback:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
        },
        authorName: 'Akachi Luccini',
        authorOccupation: 'Lead Generation, Alternative Capital',
        feedback:
            'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
];

export const jobs = [
    {
        title: 'Front-End Developer',
        location: 'Madrid',
        type: 'Remote',
    },
    {
        title: 'Community Manager',
        location: 'Paris',
        type: 'Full time',
    },
    {
        title: 'UX/UI Designer',
        location: 'Yerevan',
        type: 'Part time',
    },
];

export const news = [
    {
        cover: {
            src: 'https://assets.maccarianagency.com/the-front/photos/logistics/news1.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/logistics/news1@2x.jpg 2x',
        },
        tag: 'delivery',
        title: 'Optimize the schedules of thousands of trains in minutes.',
        date: '12 June, 2020',
    },
    {
        cover: {
            src: 'https://assets.maccarianagency.com/the-front/photos/logistics/news2.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/logistics/news2@2x.jpg 2x',
        },
        tag: 'Main stream',
        title: 'Optimize the schedules of thousands of trains in minutes.',
        date: '12 June, 2020',
    },
    {
        cover: {
            src: 'https://assets.maccarianagency.com/the-front/photos/logistics/news3.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/logistics/news3@2x.jpg 2x',
        },
        tag: 'Trucks',
        title: 'Optimize the schedules of thousands of trains in minutes.',
        date: '12 June, 2020',
    },
];

export const pricings = [
    {
        title: 'Standard License',
        subtitle: 'A pay-once license, just for you',
        price: '$49',
        priceSuffix: ' / MO',
        features: [
            'Rich, responsive landing pages',
            '100+ styled components',
            'Flexible, simple license',
            'Speedy build tooling',
            '6 months free support included',
        ],
        disclaimer: 'Fully featured 30-day free trial',
        isHighlighted: false,
    },
    {
        title: 'Extended License',
        subtitle: 'A pay-once license, just for you',
        price: '$79',
        priceSuffix: ' / MO',
        features: [
            'Rich, responsive landing pages',
            '100+ styled components',
            'Flexible, simple license',
            'Speedy build tooling',
            '6 months free support included',
        ],
        disclaimer: 'Fully featured 30-day free trial',
        isHighlighted: true,
    },
    {
        title: 'Standard License',
        subtitle: 'A pay-once license, just for you',
        price: '$49',
        priceSuffix: ' / MO',
        features: [
            'Rich, responsive landing pages',
            '100+ styled components',
            'Flexible, simple license',
            'Speedy build tooling',
            '6 months free support included',
        ],
        disclaimer: 'Fully featured 30-day free trial',
        isHighlighted: false,
    },
];
