import React from 'react';
import PropTypes from 'prop-types';
import {  useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {Link} from 'gatsby';

import { useMediaQuery, Grid } from '@material-ui/core';
import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
      fontWeight: 900,
    },
  }));




const Integrations = props => {
  const classes = useStyles();
  const { data, className, ...rest } = props;
 

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6}>
            <br/>
            <br/>
            <br/>
              <SectionHeader
              
                title={<Typography color = "textSecondary"  variant="h4" className={classes.fontWeight900}> Read & Write DNA 

                </Typography>}
  
                align="left"
                label="Integrations"
                ctaGroup={[
                  <Link to = '/Genomic'>
                  <LearnMoreLink
                    title="See all integrations"
                    href="#"
                    variant="body"
                  />
                  </Link>,
                ]}
                disableGutter
                data-aos="zoom-out-down"
                data-aos-once = "false"
              />
            
        <Typography
          data-aos="zoom-out-down"
          data-aos-once = "false"
         color="secondary" >
        Advanced genomics tools integrated with computational innovation to decode extensive genomic data are at the center of public health and healthcare. As clinical genomics hinges on the accurate reading of the genomic footprints of disease markers and therapeutic targets, advanced technologies, customizable tools, and gene panels have given birth to precision medicine. Interestingly, reading or sequencing the genome has become more accessible, faster, and affordable over the last two decades. Our globally renowned partners like Illumina and Universal Sequencing have the most qualitative platforms to fit your dream sequencing lab.
        Move over genetically modified species, whereby one or a few genes are genetically engineered. One can now write an entire new gene. As profound as it sounds, writing genomes or parts of it has been made possible through neat and innovative technologies in cloning methodologies and automation thereof. A do-it-yourself (DIY) gene writing/creating synthetic genes may seem fantasy-like; Hail—the world’s first automated gene printing platform BioXp 3250 from our partners Codex DNA . Imagine creating tens of long synthetic gene constructs in your own lab within hours, without having to do tedious plasmid preps or dealing with contamination or low yields. This can potentially accelerate advances in the fields of personalized medicine, antibody engineering, vaccine development, biologics, and more.
             
          </Typography>   
            </Grid>
            <Grid item xs={12} md={5} data-aos="fade-up">
              <Image
                data-aos="zoom-in-left"
                data-aos-once = "false"
                src="https://onwhicar.sirv.com/premas/main/Backchannel-Fables-and-Futures-175998170.gif"
                alt="Integrations"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={isMd ? 4 : 2}
            direction={isMd ? 'row' : 'column-reverse'}
          >
            <Grid item xs={12} md={6} data-aos="fade-up">
             
              
                    <Image
                      data-aos="zoom-in-left"
                      data-aos-once = "false"
                src="https://onwhicar.sirv.com/edit%20dna.png"
                alt="Integrations"
              />
                 
              
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-up">
            <br/>
            <br/>
            <br/>
              <SectionHeader
               data-aos="zoom-out-down"
               data-aos-once = "false"
                title={<Typography color = "textSecondary"  variant="h4" className={classes.fontWeight900}> Edit DNA

                </Typography>}
  

                align="left"
               
                ctaGroup={[
                  <Link to = '/Genomic'>
                  <LearnMoreLink
                    title="See all integrations"
                    href="#"
                    variant="body"
                  />
                  </Link>,
                ]}
                disableGutter
              />
                  <Typography
                   data-aos="zoom-out-down"
                   data-aos-once = "false"
                  
                  color="secondary" >
        Analogous to a film reel that can be edited with a cut here and a paste there, the genome is explored in newer ways through genome editing. Moreover, access to genome editing tools like CRISPR, TALENs, ZFNs, and MAGE are helping create new paradigms in diagnostics, therapeutic interventions, and GM foods. This has poised a revolutionary ecosystem for developing new molecular devices and game-changing immunotherapies like CARTs, genome-edited crops, and gene therapy-based treatments. In addition, drugs like Zolgensma for Spinal Muscular atrophy have paved new ways for researchers to solve long-impending healthcare problems. Our partners Twist Biosciences provide customized products assisting synthetic biology researchers in editing genomes the way they want.
        </Typography>
            </Grid>
            <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6}>
            <br/>
            <br/>
            <br/>
              {/* <SectionHeader
               data-aos="zoom-out-down"
               data-aos-once = "false"
              title={<Typography color = "textSecondary"  variant="h4" className={classes.fontWeight900}>  Edit DNA

              </Typography>}

  
                align="left"
              
                ctaGroup={[
                  <Link to = '/Cellbiology'>
                  <LearnMoreLink
                    title="See all integrations"
                    href="#"
                    variant="body"
                  />
                  </Link>,
                ]}
                disableGutter
              
              />
           
           <Typography
            data-aos="zoom-out-down"
            data-aos-once = "false"
            color="secondary" >
Analogous to a film reel that can be edited with a cut here and a paste there, the genome is explored in newer ways through genome editing. Moreover, access to genome editing tools like CRISPR, TALENs, ZFNs, and MAGE are helping create new paradigms in diagnostics, therapeutic interventions, and GM foods. This has poised a revolutionary ecosystem for developing new molecular devices and game-changing immunotherapies like CARTs, genome-edited crops, and gene therapy-based treatments. In addition, drugs like Zolgensma for Spinal Muscular atrophy have paved new ways for researchers to solve long-impending healthcare problems. Our partners Twist Biosciences provide customized products assisting synthetic biology researchers in editing genomes the way they want.
            </Typography>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-up">
              <Image
                data-aos="zoom-in-left"
                data-aos-once = "false"
                src="https://onwhicar.sirv.com/edit%20dna.png"
                alt="Integrations"
              /> */}
            </Grid>
  </Grid>
  </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Integrations.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Integrations;
