import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
// import { Image } from 'components/atoms';
import { SectionHeader, CountUpNumber } from 'components/molecules';
import { Icon } from 'components/atoms';
import { colors } from '@material-ui/core';
import { Image } from 'components/atoms';
import Confetti from 'react-confetti'



const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  placementGrid: {
    maxWidth: 320,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
 
  },


  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
  },
}));

const Counterplus = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid  container spacing={isMd ? 4 : 2}>
        <Grid  item xs={12} md={6} data-aos="fade-up">
          <Grid  container spacing={4}>
            <Grid  item xs={12}>
              <SectionHeader
              
                title={
                  <span>
                    
                     <Typography   variant="h4" className={classes.fontWeight900} component="span" >Our Years of </Typography>
                    <Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900}>Hardwork </Typography>
                    <Typography  variant="h4" className={classes.fontWeight900} component="span"> resulted in some great </Typography>
                    
                    <Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900}>Milestones</Typography>
                   
                  </span>
                }
                />
            
           
           
                <SectionHeader
                
                subtitle='Our "scientists assisting scientists" approach has helped the researcher & clinician community make better-informed decisions for their project outcome.'
                align="center"
                fadeUp
                disableGutter
                
                />
           
              
            </Grid>
  
 
            <Grid    align="center" item xs={12}>
              
          
         
              <div    align="center" className={classes.placementGrid}>
                  
                <div>
                <Icon
                  color={colors.blue}
                  fontIconClass="fas fa-cogs"
                />
                  <CountUpNumber
               
                    end={300}
                    label="INSTALLATIONS"
                    textColor="secondary"
                    suffix="+"
                  />
                </div>
                <div>
                <Icon
                  color={colors.blue}
                  fontIconClass="fas fa-search-plus"
                />
                
                  <CountUpNumber
                    end={260}
                    label="RESEARCH"
                    textColor="secondary"
                    suffix="+"
                  />
                </div>
               
             
                
              </div>
            </Grid>
            <Grid    align="center" item xs={12}>
              <div    align="center"className={classes.placementGrid}>
                <div>
                <Icon
                  color={colors.blue}
                  fontIconClass="fas fa-users"
                />
                  <CountUpNumber
                    end={ 70}
                    label="EMPLOYEES"
                    textColor="secondary"
                    suffix="+"
                  />
                </div>
                <div>
                <Icon
                  color={colors.blue}
                  fontIconClass="fas fa-copyright"
                />
                  <CountUpNumber
                    end={ 15}
                    label="BRANDS"
                    textColor="secondary"
                    suffix="+"
                  />
                </div>
   
                
              </div>
                           
          
            </Grid>
          </Grid>
       
        </Grid>
        <Grid
        className = {classes.background}
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
           <Confetti
      width="500px"
      height="300px"
      numberOfPieces = '50'
    />
          <Image 
          
          
          src ="https://onwhicar.sirv.com/premas/main/Untitled.png" />
{/*        
       <NoSsr>
                <SyntaxHighlighter language="javascript" style={vs2015} className={classes.editor}>
                   {`
// > $ npm install

// // Everything installed!

Our “scientists assisting scientists” approach has helps the researcher & clinician community in making better informed decisions for their project outcome."
Our “scientists assisting scientists” approach has helps the researcher & clinician community in making better informed decisions for their project outcome."
Our “scientists assisting scientists” approach has helps the researcher & clinician community in making better informed decisions for their project outcome."
Our “scientists assisting scientists” approach has helps the researcher & clinician community in making better informed decisions for their project outcome."s

// > $ npm start

// // LiveReload started. Opening localhost:3000
//                 `}
             </SyntaxHighlighter>
               </NoSsr> */}
      
        </Grid>
        
    
      </Grid>
     
    </div>
  );
};

Counterplus.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Counterplus;



// import React from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles, useTheme, NoSsr, Grid, Button, useMediaQuery, Typography } from '@material-ui/core';
// import { SectionHeader } from 'components/molecules';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';


// const useStyles = makeStyles(theme => ({
//   quickStartSection: {
//     '& .section-header__cta-container': {
//       [theme.breakpoints.down('xs')]: {
//         flexDirection: 'column',
//         '& .section-header__cta-item-wrapper': {
//           width: '100%',
//           '&:last-child': {
//             marginLeft: 0,
//             marginTop: theme.spacing(1),
//           },
//           '& .MuiButtonBase-root': {
//             width: '100%',
//           },
//         },
//       },
//     }
//   },
//   fontWeightBold: {
//     fontWeight: '900',
//   },
//   editor: {
//     paddingLeft: `${theme.spacing(2)}px !important`,
//     paddingRight: `${theme.spacing(2)}px !important`,
//     borderRadius: theme.spacing(1/2),
//     width: '100%',
//   },
//   logoImg: {
//     maxWidth: 100,
//   },
// }));

// const Counterplus = ({ className, ...rest }) => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
//     defaultMatches: true,
//   });

//   const docsButton = (
//     <Button size="large" variant="outlined" color="primary" component="a" href="/documentation">
//       Documentation
//     </Button>
//   );

//   const buyButton = (
//     <Button
//       size="large"
//       variant="contained"
//       color="primary"
//       component="a"
//       href="/home"
//     >
//       Get Started
//     </Button>
//   );

//   return (
//     <div className={className} {...rest}>
//       <Grid container justify="space-between" spacing={4}>
//         <Grid item xs={12}>
//           <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
//             <Grid item xs={12} md={6} data-aos={'fade-right'}>
//               <SectionHeader
//                 title={
                  
//                                      <span>
//                                           Our {' '} 
//                                           <Typography  variant="inherit" component="span"> Years of </Typography>
//                                          <Typography color="secondary" variant="inherit" component="span">Hardwork </Typography>
//                                          <Typography  variant="inherit" component="span"> resulted in some great </Typography>
                                        
//                                          <Typography color="secondary" variant="inherit" component="span">Milestones</Typography>
                                       
//                                        </span>
                    
//                 }
//                 subtitle="Our “scientists assisting scientists” approach has helps the researcher & clinician community in making better informed decisions for their project outcome."
//                 ctaGroup={[docsButton, buyButton]}
//                 align={isMd ? 'left' : 'center'}
//                 disableGutter
//                 titleVariant="h3"
//                 titleProps={{ className: classes.fontWeightBold }}
//                 className={classes.quickStartSection}
//               />
//             </Grid>
//             <Grid item container alignItems="center" xs={12} md={6} data-aos={'fade-left'}>
//               <NoSsr>
//                 <SyntaxHighlighter language="javascript" style={vs2015} className={classes.editor}>
//                   {`
// > $ npm install

// // Everything installed!


// > $ npm start

// // LiveReload started. Opening localhost:3000
//                 `}
//                 </SyntaxHighlighter>
//               </NoSsr>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item xs={12}>
//           <Grid container justify="space-between" spacing={isMd ? 4 : 2} direction={isMd ? 'row': 'column-reverse'}>
//             <Grid item xs={12} container alignItems="center" md={6} data-aos={'fade-right'}>
//               <Grid container alignItems="center" spacing={2}>
//                 <Grid item xs={12} md={6}>
//                   <Typography color="primary" variant="h4" gutterBottom>300+</Typography>
//                   <Typography color="textPrimary" variant="body1">
//                      Installations
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Typography color="primary" variant="h4" gutterBottom>260+</Typography>
//                   <Typography color="textPrimary" variant="body1">
//                     research
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Typography color="primary" variant="h4" gutterBottom>70+</Typography>
//                   <Typography color="textPrimary" variant="body1">
//                      employee
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Typography color="primary" variant="h4" gutterBottom>15+</Typography>
//                   <Typography color="textPrimary" variant="body1">
//                      Brands
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={12} md={6} data-aos={'fade-left'}>
//               <SectionHeader
//                 title="The powerful and flexible theme for all kinds of businesses"
//                 subtitle="Whether you're creating a subscription service, an on-demand marketplace, an e-commerce store, or a portfolio showcase, theFront helps you create the best possible product for your users."
//                 align="left"
//                 disableGutter
//                 titleVariant="h3"
//                 titleProps={{ className: classes.fontWeightBold }}
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// Counterplus.propTypes = {
//   /**
//    * External classes
//    */
//   className: PropTypes.string,
// };

// export default Counterplus;




